/**
 * item editor
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-printer-print {
    display: inline;
    .c-printer-print-ground {
        @include position(absolute, 0 0);
        width: 0;
        height: 1px;
        overflow: hidden;
    }
    .c-printer-iframe-print {
        padding: 0;
        border: none;
    }
}

.c-printer-privew-modal {
    &.ant-modal .ant-modal-body {
        padding: 0;
    }
    .c-printer-iframe-preview {
        width: 100%;
        min-height: 96%;
        padding: 0;
        border: none;
    }
}