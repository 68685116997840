/**
 * image viewer
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-image-viewer-warning {
    .anticon {
        float: left;
        font-size: 20px;
        color: #faad14;
        margin-right: 8px
    }
}

figure#zmage {
    #zmageControl,
    #zmageControlPagination,
    #zmageControlFlipRight,
    #zmageControlFlipLeft {
        background-color: #fff !important;
    }
    .image-size-fixed {
        @include prefixer-val(max-width, calc(100% - 20px));
        @include prefixer-val(max-height, calc(100% - 20px));
        &.zooming__8A3Sl {
            max-width: none;
            max-height: none;
        }
    }
}