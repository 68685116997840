/**
 * table merge export
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-merge-export {
    .c-merge-export-table {
        @include position(absolute, 0 0, 1000);
        width: 1px;
        height: 1px;
        overflow: hidden;
        opacity: 0;
        .no-print {
            display: none;
        }
    }
    .c-table-merge-export {
        position: relative;
        top: -1px;
    }
}

.c-merge-export-process {
    @include position(fixed, 0 0, 1000);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    .inner {
        @include position(absolute, 35% 50%);
        width: 700px;
        max-width: 90%;
        padding: 30px 30px 20px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        @include prefixer(transform, translate(-50%, -100px));
        opacity: 0;
        transition: opacity 0.3s;
        &.on {
            opacity: 1;
        }
        .icon {
            float: left;
            margin: 5px 0 0 10px;
            img {
                width: 60px;
            }
            .anticon-check {
                width: 60px;
                height: 60px;
                font-size: 40px;
                color: #fff;
                background-color: #68c639;
                border-radius: 50%;
                svg {
                    display: block;
                    margin: 10px auto 0;
                }
            }
        }
        .right {
            margin-left: 100px;
        }
        h2.title {
            strong {
                font-size: 24px;
            }
            .tips {
                margin-left: 20px;
                font-size: 14px;
                font-weight: normal;
                .c-placeholder-link {
                    font-size: 14px;
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .progress {
            width: 98%;
            padding-top: 8px;
        }
        .btn {
            padding-top: 16px;
            .ant-btn {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }
    }
}