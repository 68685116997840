/**
 * text
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-text {
    &.c-text-success {
        //color: #5FB878;
        // color: #64b964;
        color: #22c322;
    }
    &.c-text-info {
        //color: #1278FF;
        //color: #00a5e0;
        // color: #10bbcb;
        // color: #05b7c7;
        color: #04c1c8;
    }
    &.c-text-warning {
        color: #FFB800;
    }
    &.c-text-danger {
        color: #FF5722;
    }
    &.c-text-ignore {
        // color: #d2d2d2
        color: rgba(0, 0, 0, 0.5);
    }
    &.c-text-link {
        color: #1890ff;
    }
}